<template>
  <CContainer class="mt-5">
    <CRow class="justify-content-center">
      <CCol
        col="12"
        lg="7"
      >
        <CCard class="p-4">
          <CCardBody>
            <router-view />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>
<script>

import AuthService from "../auth";

export default {
  name: 'Index',
  created() {
    try {
      this.$router.push({ name: this.$store.getters.isAuthorized ? 'admin.reestrs.list' : 'login' });
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          console.error('Аккаунт заблокирован');
        } else if (e.response.status === 404) {
          console.error('Неверный E-mail или пароль');
        }
      }
    }
  },
};
</script>

<style scoped>

</style>
